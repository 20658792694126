import React from 'react'

import MainImageSlider from 'components/MainImageSlider'
import Page from 'components/Page'
import PortfolioEntries from 'components/PortfolioEntries'
import { PortfolioTextCardModel } from 'components/PortfolioTextCard/PortfolioTextCard'
import Section from 'components/Section/Section'
import { MetaData } from 'components/Seo'
import { graphql } from 'gatsby'
import { Locale } from 'utils/enums'
import { File, GatsbyPagination, NodeQuery } from 'utils/types/common.types'

import { getDataByLocale } from '../../utils/dataHelpers'
import './index.scss'

interface PortfolioDataQueries {
    portfolioPage: NodeQuery<PortfolioPageDataModel>
    allContentfulSeo: NodeQuery<MetaData>
}

interface PageContextExtraData {
    locale: Locale
    totalEntries: number
    pageLength: number
}

type Props = {
    pageContext?: GatsbyPagination<PortfolioTextCardModel, PageContextExtraData>
    data: PortfolioDataQueries
}

type PortfolioPageDataModel = {
    title: string
    readMoreButton: string
    bannerImages: File[]
}

export default ({ pageContext, data }: Props): JSX.Element => {
    const locale = pageContext?.additionalContext?.locale || Locale.de_DE
    const { portfolioPage: nodesPortfolioPage, allContentfulSeo } = data
    const portfolioEntries = pageContext?.group || []
    const metaData = getDataByLocale<MetaData>(allContentfulSeo.nodes, locale)
    const portfolioPageData = getDataByLocale<PortfolioPageDataModel>(
        nodesPortfolioPage.nodes,
        locale,
    )
    const imagesUrls = portfolioPageData.bannerImages.map((image) => image.file.url)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section fadein={false}>
                <MainImageSlider imageUrls={imagesUrls}></MainImageSlider>
            </Section>
            <Section id="portfolio-entries">
                <PortfolioEntries
                    portfolioEntries={portfolioEntries}
                    title={portfolioPageData.title}
                    buttonCardText={portfolioPageData.readMoreButton}
                    locale={locale}
                    totalEntries={pageContext?.additionalContext?.totalEntries || 0}
                    entriesByPage={pageContext.additionalContext?.pageLength}
                    pathPrefix={pageContext.pathPrefix}
                    currentPage={pageContext.index}
                ></PortfolioEntries>
            </Section>
        </Page>
    )
}

export const query = graphql`
    query QueryPortfolio2 {
        portfolioPage: allContentfulPortfolioPage(
            filter: { contentful_id: { eq: "5MK1E3LBPCvUvr21mfKk2" } }
        ) {
            nodes {
                title
                readMoreButton
                bannerImages {
                    file {
                        url
                    }
                }
                node_locale
            }
        }

        allContentfulSeo(filter: { contentful_id: { eq: "1iN5e3PNF6AsLPaB7atkEC" } }) {
            nodes {
                title
                url
                description {
                    description
                }
                image {
                    file {
                        url
                    }
                }
                node_locale
            }
        }
    }
`
